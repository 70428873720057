/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import { Alert } from "reactstrap";
// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/pages/main-hero.jpg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <p className="presentation-subtitle text-left"><b>GMC Advisory Group's </b>comprehensive work scope encompasses all the necessary building blocks to drive among the Canada and Korea companies, providing services on M&A, financial consulting, management consulting and going public.</p>
            <h4 className="presentation-title">Successful M&A and Listing Partner</h4>
          </Container>
        </div>
      </div>
      <div id="notifications">
        <Alert color="info">
          <Container>
            <span><b>Headquartered in Vancouver, British Columbia, 
              with a professional service for Canada and South Korea companies.</b></span>
          </Container>
        </Alert>
      </div>
    </>
  );
}

export default IndexHeader;
