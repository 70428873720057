/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  // Form,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ServicePageHeader from "components/Headers/ServicePageHeader.js";
import SectionGetInTouch from "views/index-sections/SectionGetInTouch.js";
import SectionFootbar from "views/index-sections/SectionFootbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function ServicePage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <IndexNavbar />
      <ServicePageHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h3 className="title">Let's talk about our Professional Service</h3>
              </Col>
            </Row>
            {/* First item */}
            <Row>
              <Col xl={6} md={6} sm={12} xs={12}>
                <div
                  style={{
                  background: "rgba(0, 0, 0, 0)",
                  backgroundImage:
                  "url(" + require("assets/img/pages/Card-1.png") + ")",
                  width: 570,
                  height: 600,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  }}>
                </div>
              </Col>
              <Col xl={6} md={6} sm={12} xs={12}>
              <div id="management" className="info" align="left" style={{marginLeft: '1.8em', marginTop: '1.8em' }}>
                <h5 className="info-title" style={{ color: '#2196f3', fontWeight: "bold" }}>Management Consulting</h5>
                <p>
                  We are supporting integrated consulting services 
                  to help you achieve your business objectives, including:
                </p>
                <ul>
                  <li style={{ color: '#2196f3', fontWeight: "bold" }}>Company Set-up</li>
                  <p>By utilizing the network of BC corporate lawyers, bankers and securities company, 
                    we help with starting new businesses in various industry.
                  </p>
                  <li style={{ color: '#2196f3', fontWeight: "bold" }}>Strategy</li>
                  <p>Business growth strategy<br/>
                    Process Innovation (SCM)<br/>
                    Reorganization and Change Management Strategy
                  </p>
                  <li style={{ color: '#2196f3', fontWeight: "bold" }}>Operation</li>
                  <p>We help our clients with any operational issues, proactively minimizing negative 
                    impacts while keeping maximum operational efficiency.
                  </p>
                </ul>
              </div>
              </Col>
            </Row>
            {/* second item */}
            <Row>
            <Col xl={6} md={6} sm={12} xs={12}>
              <div id="listing" className="info" align="left" style={{marginLeft: '1.8em', marginTop: '1.8em' }}>
                <h5 className="info-title" style={{ color: '#2196f3', fontWeight: "bold" }}>Listing Consulting</h5>
                <b style={{ color: '#2196f3' }}>Fast-Track Solution for Going Public in Canada</b><br/>
                <ul><br/>
                  <li>Advantages of being listed on the TSX/TSXV Market.</li><br/>
                  <li>The Canadian Stock Market (TMX Group) is the world’s 6th largest capital markets. TSX is the 9th largest company in the world.</li><br/>
                  <li>Canadian market is attractive to a wide range of investors in North America.</li><br/>
                  <li>If your company is listed on the TSX/TSXV, there is a higher chance for your company’s brand value to be exposed to global market.</li><br/>
                  <li>The cost of maintaining on TSX/TSXV is competitively lower and inexpensive and your company may elect to go dual listing. (ie : NASDAQ)</li><br/>
                  <li>Canada's tax system is rational and stable, capital gain is taxed at 25%, and there are no inheritance tax or gift tax system.</li><br/>
                  <li>Increasing number of cases where Small and Medium-Size(SME) Asian technology companies listing on TSX-V because the listing procedure is relatively easy and attracting large investments.</li><br/>
                </ul>
                <Button
                  className="btn-round ml-1"
                  color="primary"
                  href="/service/fasttracksolution/#bottom"
                  outline
                  // target="_blank"
                >
                <span><i class="fa fa-bullhorn" aria-hidden="true"></i></span>
                <span class="text"> See more</span>
              </Button>
              </div>
              </Col>
              <Col xl={6} md={6} sm={12} xs={12}>
                <div
                  style={{
                  backgroundImage:
                  "url(" + require("assets/img/pages/Card-2.png") + ")",
                  width: 570,
                  height: 700,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}>
                </div>
              </Col>
            </Row>
            {/* third item */}
            <Row>
              <Col xl={6} md={6} sm={12} xs={12}>
                <div
                  style={{
                  backgroundImage:
                  "url(" + require("assets/img/pages/Card-3.png") + ")",
                  width: 570,
                  height: 760,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  }}>
                </div>
              </Col>
              <Col xl={6} md={6} sm={12} xs={12}>
              <div id="m&a" className="info" align="left" style={{marginLeft: '1.8em', marginTop: '1.8em' }}>
                <h5 className="info-title" style={{ color: '#2196f3', fontWeight: "bold" }}>M&A Consulting</h5>
                <p>GMC Advisory Group provides professional services to Canadian and Korean companies in a M&A deal structure.</p><br/>
                <ul>
                  <li style={{ color: '#2196f3', fontWeight: "bold" }}>Mergers and Acquisitions</li>
                  <p>
                    We support M&A deal throughout the processes from finding targets 
                    to post merger integration. Our strategy-driven approach addresses 
                    every value-impacting aspect of a merger, from operational and technological synergy 
                    to retain profitable customers, maintaining business relationships, 
                    and settling employee apprehension that naturally arises.
                  </p> 
                  
                  <li style={{ color: '#2196f3', fontWeight: "bold" }}>Corporate Due Diligence</li>
                  <p>
                    Our team is consisted of experienced Canadian CPA and lawyers, 
                    and we utilize our global connections to expert groups in Canada and Korea.
                  </p>
                  <li style={{ color: '#2196f3', fontWeight: "bold" }}>Post Merger Integration</li>
                  <p>
                    At the request of the customer, we can provide continuous support until the company is settled after the merger. 
                    This will also be supported by a group of experienced professionals.
                  </p>
                  <li style={{ color: '#2196f3', fontWeight: "bold" }}>Spin-offs and Joint Ventures</li>
                  <p>
                    We help clients to build restructuring strategies such as Spin-off and Joint Venture.
                  </p>
                </ul>
              </div>
              </Col>
            </Row>

          </Container>
        </div>
      </div>
      <SectionGetInTouch />
      <SectionFootbar />
      <DemoFooter />
    </>
  );
}

export default ServicePage;
