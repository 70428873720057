/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionFootbar() {
  return (
    <>
      <Container className="tim-container">
        <br />
        <div id="images">
          <Container>
            <Row>
              <Col className="mr-auto" md="4" sm="3">
                <div class="textwidget">
                  <br />
                  <br />
                  <p><img src={require("assets/img/pages/GMC_logo_360x152.png")} style={{ width: "60%" }}/>
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" md="4" sm="3">
                <ul>
                  <h3 class="title widget-title">GMC Advisory Group</h3>
                  <div class="textwidget">
                    <p>Office: #31 - 1125 Kensal Place, Coquitlam, <br />
                        British Columbia, Canada V3B 0G3
                    </p>
                    <p>Email: <a href="mailto:info@gmc-advisory.com">info@gmc-advisory.com</a>
                    </p>
                  </div>
                </ul>
              </Col>
              {/* <Col className="mr-auto" md="4" sm="3">
                <ul>
                  <h3 class="title widget-title">About Us</h3>
                  <div class="textwidget">
                    <p>GMC Advisory utilize telecommunications technologies and applications to supply a simple, convergent solution for the utilities industry, including Advanced Metering Infrastructure(AMI) Systems.
                    </p>
                  </div>
                </ul>
              </Col> */}
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default SectionFootbar;