/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg,
  // Form,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";

import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, CardText } from 'reactstrap';
import classnames from 'classnames';

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
// import ServicePageHeader from "components/Headers/ServicePageHeader.js";
import FastTrackPageHeader from "components/Headers/FastTrackPageHeader.js";
import SectionGetInTouch from "views/index-sections/SectionGetInTouch.js";
import SectionFootbar from "views/index-sections/SectionFootbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function FastTrackPage() {

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("/service/fasttracksolution");
    return function cleanup() {
      document.body.classList.remove("/service/fasttracksolution");
    };
  });
  return (
    <>
      <IndexNavbar />
      <FastTrackPageHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col>
                <h3 className="title">Fast-Track solution for the Listing in Canada<br /> 
                  -  The Capital Pool Company(CPC) Program
                </h3>
              </Col>
            </Row>
            {/* second item */}
            <Row>
              <Col>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    Introduction
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    Stage One
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggle('3'); }}
                  >
                    Stage Two
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => { toggle('4'); }}
                  >
                    Closing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => { toggle('5'); }}
                  >
                    Requirements
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                {/* 1 - Introduction */}
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                    <div className="info" align="left" style={{marginLeft: '1.8em', marginTop: '1.8em' }}>
                      <h5 className="info-title" style={{ color: '#2196f3', fontWeight: "bold" }}>Introduction</h5>
                      <p><span>
                      The Capital Pool Company program is a unique two-stage listing process offered by the TSX Venture Exchange (the “TSXV”) 
                      which brings together experienced participants in public capital markets with entrepreneurs seeking funding and a public listing. 
                      In stage one of the process, a new company (known as a “Capital Pool Company”) is listed on the TSXV by way of an initial public offering. 
                      In stage two (the “Qualifying Transaction”), the Capital Pool Company acquires an asset or completes a transaction with a private business 
                      which results in the listing of the acquired business on the TSXV. If the acquired business can meet the minimum listing requirements 
                      of the Toronto Stock Exchange (the “TSX”), it can be directly listed on the TSX at the closing of the Qualifying Transaction. In many cases,{' '}
                      <b style={{ color: '#2196f3', textDecorationLine: 'underline'}}>the listing of a business or asset through the Capital Pool 
                      Company program can be a more cost and time efficient alternative than a listing through a traditional initial public offering.</b>
                      </span></p>
                      <br />
                      <h5 className="info-title" style={{ color: '#2196f3', fontWeight: "bold" }}>General CPC(Capital Pool Company) Listing Structure</h5>
                      <img top width="100%" src={require("assets/img/pages/fasttrack-1.jpg")} alt="..." />
                    </div>
                    </Col>
                  </Row>
                </TabPane>
                {/* 2 - Stage One */}
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                    <div className="info" align="left" style={{marginLeft: '1.8em', marginTop: '1.8em' }}>
                      <h5><span className="info-title" style={{ color: '#2196f3', fontWeight: "bold" }}>Stage One</span><span> – Listing The Capital Pool Company</span></h5>
                      <p><span>
                      In the first stage of the Capital Pool Company program, a group of experienced capital markets participants 
                      coordinates the completion of an initial public offering by a new shell company and the listing of its common shares on the TSXV. 
                      {' '}<b style={{ color: '#2196f3', textDecorationLine: 'underline'}}>This shell company must adhere to the TSXV’s governance requirements,</b>
                      {' '}including the requirement that each proposed director and senior officer must be either a resident of Canada or the United States 
                      or have relevant public company experience in a foreign jurisdiction. A Capital Pool Company must have, at minimum, {' '}
                      <b style={{ color: '#2196f3', textDecorationLine: 'underline'}}>three directors (two of whom must be independent under TSXV rules), 
                      one officer who can serve as Chief Executive Officer and Chief Financial Officer, and one Corporate Secretary.</b>
                      </span></p>
                      <p><span>
                      A financing, through an agent who is registered under applicable securities laws, must be completed in conjunction 
                      with the initial public offering. The gross proceeds to the Capital Pool Company from the 
                      {' '}<b style={{ color: '#2196f3', textDecorationLine: 'underline'}}>initial public offering must be equal to or greater than $200,000 and must not exceed $4,750,000.</b>
                      {' '}Between the completion of the initial public offering and the Qualifying Transaction, 
                      a Capital Pool Company is generally not permitted to raise additional funds unless written consent is obtained.
                      </span></p>
                      <p><span>
                      After the initial public offering, the principal activity of the Capital Pool Company is seeking an asset or business 
                      with which to complete its Qualifying Transaction. Management is also responsible for compliance with the ongoing obligations 
                      of the Capital Pool Company, including the completion and public filing of financial statements and related management’s 
                      discussion and analysis and holding an annual shareholders meeting. 
                      </span></p>
                      <br />
                    </div>
                    </Col>
                  </Row>
                </TabPane>
                {/* 3 - Stage Two */}
                <TabPane tabId="3">
                <Row>
                    <Col sm="12">
                    <div className="info" align="left" style={{marginLeft: '1.8em', marginTop: '1.8em' }}>
                      <h5><span className="info-title" style={{ color: '#2196f3', fontWeight: "bold" }}>Stage Two</span><span> – Qualifying Transaction(QT)</span></h5>
                      <p><span>
                      Once a suitable asset or business has been identified (the “Target”), the Capital Pool Company can complete a Qualifying Transaction. 
                      In order to be accepted by the TSXV, the proposed company resulting from the 
                      {' '}<b style={{ color: '#2196f3', textDecorationLine: 'underline'}}>Qualifying Transaction (also known as the Resulting Issuer) must 
                      be able to meet the initial listing requirements set out in the TSXV’s policies.</b>
                      {' '}These requirements vary depending on the proposed industry segment of the Resulting Issuer 
                      under the TSXV’s initial listing requirements (Mining, Oil & Gas, Industrial/Technology/Life Sciences, 
                      or Real Estate/Investment) and whether the Resulting Issuer will be a Tier 1 or Tier 2 company. 
                      Please refer to the initial listing requirements(Last page).
                      </span></p>
                      <p><span>
                      Once an agreement has been entered into by the Capital Pool Company and the Target (or the owners of the Target, if applicable) 
                      regarding a proposed Qualifying Transaction, a comprehensive news release must be disseminated, setting out the details of the proposed transaction 
                      and the assets and/or business to be acquired. The next steps in completing the Qualifying Transaction will include:
                      </span></p>
                      <br />
                      <ul>
                        <li>Obtaining Audited and Pro Forma Financial Statements<b style={{ color: '#2196f3'}}> (Audited Financial Statements)</b></li>
                        <li>Filing Personal Information Forms<b style={{ color: '#2196f3'}}> (New director and officer)</b></li>
                        <li>Preparing an Information Circular or Filing Statement<b style={{ color: '#2196f3'}}> (SEDAR, www.sedar.com)</b></li>
                        <li>Completing Technical Reports<b style={{ color: '#2196f3'}}> (If the Resulting Issuer will be a mining or oil & gas company)</b></li>
                        <li>Engaging a Sponsor<b style={{ color: '#2196f3'}}> (In certain cases, Investment bank or other TSXV-qualified advisor)</b></li>
                      </ul>
                      <br />
                    </div>
                    </Col>
                  </Row>
                </TabPane>
                {/* 4 - Closing */}
                <TabPane tabId="4">
                  <Row>
                    <Col sm="12">
                    <div className="info" align="left" style={{marginLeft: '1.8em', marginTop: '1.8em' }}>
                      <h5><span className="info-title" style={{ color: '#2196f3', fontWeight: "bold" }}>Closing and Post-Closing Matters</span></h5>
                      <p><span>
                      The TSXV will issue a final bulletin when the Qualifying Transaction has been completed and the TSXV         
                      has received and approved all of the final documentation required to be submitted. The bulletin will 
                      indicate that the Qualifying Transaction has been completed and also specify a date on which the 
                      Resulting Issuer’s shares will commence trading under its new name. Post closing, the new board of 
                      directors will have to meet and attend to various matters relating to the governance of the Resulting Issuer.
                      </span></p>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                    </Col>
                  </Row>
                </TabPane>
                {/* 5 - Requirement */}
                <TabPane tabId="5">
                  <Row>
                    <Col sm="12">
                    <div className="info" align="left" style={{marginLeft: '0.5em', marginRight: '0.5em', marginTop: '1.0em' }}>
                      <Table bordered>
                        <thead>
                          <tr>
                            <th colSpan="3">INITIAL LISTING REQUIREMENTS - INDUSTRIAL OR TECHNOLOGY OR LIFE SCIENCES SEGMENT</th>
                          </tr>
                          <tr>
                            <th style={{width:"25%"}}>Listing Requirement</th>
                            <th>Tier One</th>
                            <th>Tier Two</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Net Tangible Assets or Revenue (as applicable)</td>
                            <td>$5,000,000 NTA or $5,000,000 revenue</td>
                            <td>$750,000 NTA or $500,000 in revenue or $2,000,000 Arm's Length Financing</td>
                          </tr>
                          <tr>
                            <td>Property or Reserves</td>
                            <td>Issuer has Significant Interest in business or primary asset used to carry on business</td>
                            <td>Issuer has Significant Interest in business or primary asset used to carry on business</td>
                          </tr>
                          <tr>
                            <td>Prior Expenditures and Work Program</td>
                            <td>History of operations or validation of business</td>
                            <td>History of operations or validation of business</td>
                          </tr>
                          <tr>
                            <td>Working Capital and Financial Resources</td>
                            <td>(i) Adequate Working Capital and Financial Resources to carry out stated work program 
                              or execute business plan for 18 months following listing and (ii) $200,000 in unallocated funds</td>
                            <td>(i) Adequate Working Capital and Financial Resources to carry out stated work program 
                              or execute business plan for 12 months following listing and (ii) $100,000 in unallocated funds</td>  
                          </tr>
                          <tr>
                            <td>Public Distribution</td>
                            <td>(i) Public Flot of 1,000,000 shares, (ii) 250 Public Shareholders each holding a Board Lot 
                              and having no Resale Restrictions on their shares and (iii) 20% of issued and outstanding shares in the hands of Public Shareholders</td>
                            <td>(i) Public Flot of 500,000 shares, (ii) 200 Public Shareholders each holding a Board Lot 
                              and having no Resale Restrictions on their shares and (iii) 20% of issued and outstanding shares in the hands of Public Shareholders</td>
                          </tr>
                          <tr>
                            <td>Other Criteria</td>
                            <td colSpan="2">If no revenue, two year management plan demonstrating reasonable likelihood of revenue within 24 months
                            (Sponsor Report if required)</td>
                          </tr>
                        </tbody>
                      </Table>
                      <p>* Please refer to the TSXV Corporate Finance Manual for all defined terms used in the above table.</p>
                    </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
              </Col>
            </Row>
            {/* third item */}
            {/* <Row>
              <Col xs={6}>
                <div class="vc_single_image-wrapper   vc_box_border_grey">
                  <img
                    alt="..."
                    width="570" height="760"
                    src={require("assets/img/pages/Card-3.png")}
                  />  
                </div>
              </Col>
              <Col xs={6}>
              <div className="info" align="left" style={{marginLeft: '1.8em', marginTop: '1.8em' }}>
                <h5 className="info-title" style={{ color: '#2196f3', fontWeight: "bold" }}>M&A Consulting</h5>
                <p>GMC Advisory Group provides professional services to Canadian and Korean companies in a M&A deal structure.</p><br/>
                <ul>
                  <li style={{ color: '#2196f3', fontWeight: "bold" }}>Mergers and Acquisitions</li>
                  <p>
                    We support M&A deal throughout the processes from finding targets 
                    to post merger integration. Our strategy-driven approach addresses 
                    every value-impacting aspect of a merger, from operational and technological synergy 
                    to retain profitable customers, maintaining business relationships, 
                    and settling employee apprehension that naturally arises.
                  </p> 
                  
                  <li style={{ color: '#2196f3', fontWeight: "bold" }}>Corporate Due Diligence</li>
                  <p>
                    Our team is consisted of experienced Canadian CPA and lawyers, 
                    and we utilize our global connections to expert groups in Canada and Korea.
                  </p>
                  <li style={{ color: '#2196f3', fontWeight: "bold" }}>Post Merger Integration</li>
                  <p>
                    At the request of the customer, we can provide continuous support until the company is settled after the merger. 
                    This will also be supported by a group of experienced professionals.
                  </p>
                  <li style={{ color: '#2196f3', fontWeight: "bold" }}>Spin-offs and Joint Ventures</li>
                  <p>
                    We help clients to build restructuring strategies such as Spin-off and Joint Venture.
                  </p>
                </ul>
                <Button className="btn-link" color="info" href="#">
                  See more
                </Button>
              </div>
              </Col>
            </Row> */}

          </Container>
        </div>
        {/* <div className="section section-dark text-center">
          <Container>
            <h2 className="title">Let's talk about us</h2>
            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/clem-onojeghuo-3.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Henry Ford</CardTitle>
                        <h6 className="card-category">Strategist</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Teamwork is so important that it is virtually impossible
                      for you to reach the heights of your capabilities or make
                      the money that you want without becoming very good at it.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/joe-gardner-2.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Sophie West</CardTitle>
                        <h6 className="card-category">Planner</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      A group becomes a team when each member is sure enough of
                      himself and his contribution to praise the skill of the
                      others. No one can whistle a symphony. It takes an
                      orchestra to play it.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/erik-lucatero-2.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Robert Orben</CardTitle>
                        <h6 className="card-category">IT Developer</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      The strength of the team is each individual member. The
                      strength of each member is the team. If you can laugh
                      together, you can work together, silence isn’t golden,
                      it’s deadly.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div> */}
      </div>
      <SectionGetInTouch />
      <SectionFootbar />
      <DemoFooter />
    </>
  );
}

export default FastTrackPage;
