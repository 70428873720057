/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Coded by Magik Goblin

=========================================================
*/

import React from "react";
import firebase from 'firebase';

// reactstrap components
import {
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyA1Uo90sVTtzGV1LSgAlIiQnKrUJqejOfU",
  authDomain: "gmc-advisory.firebaseapp.com",
  databaseURL: "https://gmc-advisory.firebaseio.com",
  projectId: "gmc-advisory",
  storageBucket: "gmc-advisory.appspot.com",
  messagingSenderId: "524974516297",
  appId: "1:524974516297:web:5732957431d846cb3af798",
  measurementId: "G-DTLPK3JV5J"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var firestore = firebase.firestore();
const db = firestore.collection("contactData"); 

class SectionContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.name = React.createRef();
    this.email = React.createRef();
    this.message = React.createRef();
    this.time = React.createRef();
  }

  handleSubmit(event) {
    const timestamp = Date.now();
    const timesent = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp);
    // alert('Name: ' + this.name.current.value + '\n'
    // + 'Email: ' + this.email.current.value + '\n' +
    // 'notes: ' + this.notes.current.value + '\n' +
    // 'time: ' + timesent);
    
    db.doc().set({
      name: this.name.current.value,
      email: this.email.current.value,
      message: this.message.current.value,
      time: timesent,
    }).then(function() {
      console.log("Data Saved");
    }).catch(function(error) {
      console.log(error);
    });

    this.name.current.value = "";
    this.email.current.value = "";
    this.message.current.value = "";

    event.preventDefault();
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="section landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Contact Us</h2>
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Name</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          placeholder="Name" 
                          type="text" 
                          innerRef={this.name}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          placeholder="Email" 
                          type="text" 
                          innerRef={this.email} 
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Message</label>
                  <Input
                    placeholder="Tell us your business plan..."
                    type="textarea"
                    rows="4"
                    innerRef={this.message}
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" color="danger" size="lg">
                        Send message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </form>
    );
  }
}

export default SectionContactUs;