/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { Card, CardHeader, CardDeck, CardImg, CardImgOverlay, CardBody, CardTitle, CardText, Nav, NavItem, NavLink } from 'reactstrap';

// core components

function SectionSplit() {
  return (
    <>
      <div className="section">
        <Container>
          <Row className="example-page">
            <CardDeck>
              <Card>
                <CardImg top src={require("assets/img/pages/Card-1.png")} alt="..." style={{ width: "100%" }}></CardImg>
                <CardImgOverlay>
                <div class="card-img-overlay d-flex flex-column align-items-end">
                  <Button
                    className="btn-outline-neutral btn-round bottom"
                    color="default"
                    href="/service/#management"
                  >
                    Learn more
                  </Button>
                </div>
                </CardImgOverlay>
                <CardBody>
                    <CardTitle><h4 className="text-muted">Management Consulting</h4></CardTitle>
                    <CardText>GMC Advisory Group helps start-up and newcomer facing with various management issues including but not limited to incorporation, strategies, budget, operational issues and etc.</CardText>
                </CardBody>
              </Card>
              <Card className="text-left">
                <CardImg top src={require("assets/img/pages/Card-2.png")} alt="..." style={{ width: "100%" }}></CardImg>
                <CardImgOverlay>
                <div class="card-img-overlay d-flex flex-column align-items-end">
                  <Button
                    className="btn-outline-neutral btn-round bottom"
                    color="default"
                    href="/service/#listing"
                  >
                    Learn more
                  </Button>
                  </div>
                </CardImgOverlay>
                <CardBody>
                    <CardTitle><h4 className="text-muted">Listing Consulting</h4></CardTitle>
                    <CardText>GMC Advisory Group assists various companies looking to go public, helping with IPO, Reverse Takeover(RTO) and Canadian Pooled Company(CPC).</CardText>
                </CardBody>
              </Card>
              <Card>
                <CardImg top src={require("assets/img/pages/Card-3.png")} alt="..." style={{ width: "100%" }}></CardImg>
                <CardImgOverlay>
                <div class="card-img-overlay d-flex flex-column align-items-end">
                  <Button
                    className="btn-outline-neutral btn-round bottom"
                    color="default"
                    href="/service/#m&a"
                  >
                    Learn more
                  </Button>
                </div>
                </CardImgOverlay>
                <CardBody>
                    <CardTitle><h4 className="text-muted">M&A Consulting</h4></CardTitle>
                    <CardText>GMC Advisory Group provides professional services to Canadian and Korean companies in a M&A deal structure.</CardText>
                </CardBody>
              </Card>
            </CardDeck>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionSplit;
