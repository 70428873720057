/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function SectionGetInTouch() {
  return (
    <>
      <div className="section section-blue text-center">
        <Container>
          <Row className="align-items-center">
            <Col md="9">
              <h4 className="title">
                Get In Touch Today
              </h4>
              <p class="title">
                To learn more about our solutions
              </p>
            </Col>
            <Col md="3">
              <Button
                className="btn-round ml-1"
                color="white"
                href="/about-us"
                outline
              >
                <span><i class="fa fa-envelope" aria-hidden="true"></i></span>
                <span class="text"> Contact Us</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionGetInTouch;